<template>
  <div style="width: 100%;">
    <div class="tabBox user" style="padding: 0; border: none;">
      <div class="boxCol2-2" style="margin-top: 0; width:100%; height:500px; display:flex;">
        <div class="tableWrap box one" style="border: none; height: auto; padding:0;">
          <div class="tableBox" style="float: right; height: 100%;;">
            <table>
              <tr>
                <th>No</th>
                <th>{{ $t("livemap-data-way-name") }}</th>
                <th>{{ $t("livemap-data-way-dept") }}</th>
                <th>{{ $t("livemap-data-way-operator") }}</th>
                <th>{{ $t("livemap-data-way-drone") }}</th>
                <th>{{ $t("livemap-data-way-startdate") }}</th>
              </tr>
              <template v-if="total > 0">
                <tr v-for="(data, i) in liveMapList" :key="i" @click="handleSelectEl(data)"  :class="{ active: selectEl != null && selectEl.waypointLogId == data.waypointLogId }">
                  <td>{{ total - (currentPage * size + i) }}</td>
                  <td>{{ data.title}}</td>
                  <td>{{ data.userDepartmentName }}</td>
                  <td>{{ data.userName }}</td>
                  <td>{{ data.deviceModelName }}</td>
                  <td>{{ moment(data.startDate).format("YYYY.MM.DD HH:mm")}}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6" style="text-align: center;">
                    {{$t("data-not-found")}}
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" style="padding-top: 15px;">
      <el-pagination
          small
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
          @current-change="handleCurrentChange"
          :current-page="currentPage + 1"
      >
      </el-pagination>
    </div>
    <div class="map" style="width: calc(40% - 40px);height: calc(90% - 107px);position: absolute;right: 30px;bottom: 57px;background: #fff;" >
      
        <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" />
      <MapButtonComponent class="opticsList-button" ref="buttonMap"/>
      
      

      <button v-if="selectEl != null" class="point medium" style="width:150px; position: absolute; left: 40%; bottom: 50px;background: #0080FF;border: 1px solid #0080FF;border-radius: 5px;"  @click="handleDetail">
        <a href="javascript:void(0);">{{ $t("btn-detail") }}</a>
      </button>
    </div>
  </div>
</template>
<style>
div.liveMapMark {
  position: absolute;
  width: 60px;
  height: 18px;
  right: 12px;
  top: 9px;
  padding: 2px 0;
  text-align: center;
  background-color: #ffc002;
  border-radius: 9px;
}
span.markTxt {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}
.liveMap-list-box{border: 1px solid #ddd;margin-bottom: 5px;border-radius: 5px;padding: 10px;}
.liveMap-list-box-header{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding-bottom: 10px;}
.liveMap-list-title > h2{font-size: 1.4rem; font-weight: bold;margin-bottom: 5px;}
.liveMap-list-title > h4{font-size: 1.2rem;font-weight: 400;color: #969696;}
.liveMap-list-number{width: 25px;height: 25px;background: #0080FF;border-radius: 50px;text-align: center;}
.liveMap-list-number > span{color: #fff;display: block;padding-top: 3px;}

.liveMap-list-box:hover{border: 1px solid #6992F9;cursor: pointer;}
.liveMap-list-box.active{border: 1px solid #6992F9;}
.liveMap-list-box.active > .liveMap-list-box-header > .liveMap-list-title > h2{color: #0080FF;}

.liveMap-list-box > ul{padding-top: 10px;}
.liveMap-list-box > ul > li{display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;}
.liveMap-list-box > ul > li:nth-child(3){margin-bottom: 0;}
.liveMap-list-box > ul > li > span{width: 75px;background: #eee;text-align: center;border-radius: 10px;color: #969696;font-size: 1.2rem;}
.liveMap-list-box > ul > li > h4{font-size: 1.2rem;font-weight: 400;}
.liveMap-list-none{font-size: 1.6rem;text-align: center;padding-top: 20rem;}

</style>
<script>
import moment from "moment";
import {fetchWaypointLocationList} from  "@/api/missionWaypointLocation";
import { fetchEvent } from "@/api/event";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import { fetchWaypointLogList } from "@/api/missionWaypointLog";
import {mapState} from "vuex";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  components: { Map, MapButtonComponent },
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      currentPage: 0,
      total: 0,
      size: 15,
      selectEl : null,
      liveMapList: [],
      loadMap : 'kakao',
      keyword: '',
      keywordType: 'all',
      startYmd: null,
      endYmd: null,

      windowHeight: window.innerHeight,
      typeChangeUpShowHeight: 0,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });
    this.preset();
    window.addEventListener('resize', this.handleResize);
    this.typeChangeUpShowHeight = this.windowHeight - 367;
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    //this.getWaypointLogList();
    this.getEvent();
    this.initButtonMap()
  },
  methods: {
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
    },
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeUpShowHeight = this.windowHeight - 367;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
      
        if(this.selectEl != null) {
          this.getWaypointLocationList();
        }
      })
    },
    handleDetail() {
      let params = {
        keyword: this.keyword,
        keywordType: this.keywordType,
        currentPage: this.currentPage,
        startYmd: this.startYmd,
        endYmd: this.endYmd
      };
      this.persist(params);
      if(this.selectEl != null) {
        this.$router.push({ name: "LiveMapThermalDetail" , query: {eventId : this.eventId, waypointId: this.selectEl.waypointId, refId: this.selectEl.waypointLogId, contentsId: this.selectEl.contentsId, deviceModelName: this.selectEl.deviceModelName, deviceName : this.selectEl.deviceName , startDate: this.selectEl.startDate}})
        //this.$emit("select-live-map", this.selectEl.waypointId, this.selectEl.waypointLogId, this.selectEl.contentsId, this.selectEl.deviceModelName)
      }
    },
    handleSelectEl(data){
      this.$refs.map.onClearMapData();
      if(this.selectEl != null && this.selectEl.waypointLogId == data.waypointLogId) {
        this.selectEl = null;
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat);
        return;
      }
      this.selectEl = data;
      this.getWaypointLocationList();
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    async getWaypointLogList() {
      let params = {
        page_page: this.currentPage,
        page_sort : "startDate,DESC",
        page_size: this.size,
        eventId : this.eventId,
        isLiveMap : true,
        isThermal : true,
        isStartTime : true,
        keyword: this.keyword,
        keywordType: this.keywordType,
      };
      let startYmd = this.startYmd;
      let endYmd = this.endYmd;
      if(startYmd != null && endYmd != null) {
        params["dateRange.from"] = moment(startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(endYmd).format("YYYY-MM-DD");
      }

      await fetchWaypointLogList(params).then((res) => {
        this.total = res.data.data.totalElements;
        this.$parent.total = res.data.data.totalElements;
        this.liveMapList = res.data.data.content;
      });
    },
    getWaypointLocationList(){
      let params = {
        eventId : this.eventId,
        waypointId : this.selectEl.waypointId
      };

      fetchWaypointLocationList(params).then((res) => {
        if (res.data.result == 0) {
          var waypointLocationList = res.data.data.content;
          let sort = waypointLocationList.sort((a,b) => {return a.index - b.index})
          let mapDataType = MapDataType.ID_WAYPOINT
          if( sort.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

            sort.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            })
            this.createMapLine("way-point", sort);
          } else {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getWaypointLogList();
    },
    search(keyword, keywordType, currentPage, startYmd, endYmd){
      this.keyword = keyword;
      this.keywordType = keywordType;
      this.currentPage = currentPage;
      this.startYmd = startYmd;
      this.endYmd = endYmd;
      this.getWaypointLogList();
    },
    preset(){
      let localStorageData = localStorage.getItem("liveMap_thermal_searchData");
      if(localStorageData){
        let searchData = JSON.parse(localStorageData);
        this.search(searchData.keyword, searchData.keywordType, searchData.currentPage, searchData.startYmd, searchData.endYmd);
        this.$parent.keyword = searchData.keyword;
        this.$parent.keywordType = searchData.keywordType;
        this.$parent.startYmd = searchData.startYmd;
        this.$parent.endYmd = searchData.endYmd;
      }else{
        this.$parent.keywordType = "all";
        this.getWaypointLogList();
      }
    },
    persist(params){
      localStorage.setItem("liveMap_thermal_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    }
  },
};
</script>
